import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "mdx"
    }}>{`MDX`}</h1>
    <h3 {...{
      "id": "markdown-for-the-component-era"
    }}>{`Markdown for the component era`}</h3>
    <p>{`MDX is an authorable format that lets you seamlessly write JSX in your Markdown
documents.  You can import components, such as interactive charts or alerts, and
embed them within your content.  This makes writing long-form content with
components a blast 🚀.`}</p>
    <h4 {...{
      "id": "try-it"
    }}>{`Try it`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-.mdx"
      }}>{`# Hello, *world*!

Below is an example of JSX embedded in Markdown. <br /> **Try and change
the background color!**

<div style={{ padding: '20px', backgroundColor: 'tomato' }}>
  <h3>This is JSX</h3>
</div>
`}</code></pre>
    <p>{`❤️ `}<strong parentName="p">{`Powerful`}</strong>{`: MDX blends markdown and JSX syntax to fit perfectly in
JSX-based projects.`}</p>
    <p>{`💻 `}<strong parentName="p">{`Everything is a component`}</strong>{`: Import JSX components and render them
directly in your MDX document.`}</p>
    <p>{`🔧 `}<strong parentName="p">{`Customizable`}</strong>{`: Decide which component is rendered for each markdown
element.`}</p>
    <p>{`📚 `}<strong parentName="p">{`Markdown-based`}</strong>{`: The simplicity and elegance of Markdown remains,
you interleave JSX only when you want to.`}</p>
    <p>{`🔥 `}<strong parentName="p">{`Blazingly blazing fast`}</strong>{`: MDX has no runtime, all compilation occurs
during the build stage.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“It’s extremely useful for using design system components to render markdown
and weaving interactive components in with existing markdown.”`}</p>
      <p parentName="blockquote">{`— `}<a parentName="p" {...{
          "href": "https://twitter.com/chrisbiscardi/status/1022304288326864896"
        }}>{`@chrisbiscardi`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "why"
    }}>{`Why?`}</h2>
    <p>{`Before MDX, some of the benefits of writing Markdown were lost when integrating
with JSX.  Implementations were often template string-based which required lots
of escaping and cumbersome syntax.`}</p>
    <p>{`MDX seeks to make writing with Markdown `}<em parentName="p">{`and`}</em>{` JSX simpler while being more
expressive.  Writing is fun again when you combine components, that can
even be dynamic or load data, with the simplicity of Markdown for long-form
content.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=d2sQiI5NFAM&list=PLV5CVI1eNcJgCrPH_e6d57KRUTiDZgs0u"
        }}>{`Watch some of these features in action`}</a></p>
    </blockquote>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      